@import url('https://fonts.googleapis.com/css?family=Cabin:400,600,700&display=swap');

$lightGradien: #373C40;
$darkGradien: #000000;
$primaryColor: #009DFF;
$lightColor: #FFFFFF;
$darkColor: #000000;

html{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

h1, h2, h3, h4, h5, span, p{
    margin: 0;
    padding: 0;
    font-family: 'Cabin', sans-serif;
    color: $lightColor;
}

button{
    background: none;
    border: none;
    color: $lightColor;
}

body{
    background: $darkColor;
    position: relative;

    .App{
        position: relative;
    }

    .gradient-background{
        background: radial-gradient($lightGradien, $darkGradien);
        opacity: .7;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 5;
    }

    .main-content{
        position: absolute;
        display: flex;
        top: 0;
        height: 100vh;
        width: 100vw;
        background: transparent;
        z-index: 10;

        .text-content{
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: fit-content;
            margin: auto;

            .name{
                position: relative;
                
                span{
                    position: absolute;
                    left: 0;
                    top: -14px;
                    color: $primaryColor;
                    font-weight: 900;
                }
            }

            .social-media{
                display: flex;
                justify-content: center;

                a{
                    margin: 10px 20px;
                    color: white;
                    outline: none;
                    cursor: pointer;
                    transition: all ease 250ms;

                    &:hover{
                        transform: translate3d(0,0,123px)
                    }
                }
            }
        }
    }

    #top-left-triangle{
        position: absolute;
        display : inline-block;
        height : 0;
        width : 0;
        border-top : 35vh solid red;
        border-right : 40vw solid transparent;
        z-index: 20;
    }
}