@import url(https://fonts.googleapis.com/css?family=Cabin:400,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100vh;
  width: 100vw;
  overflow: hidden; }

h1, h2, h3, h4, h5, span, p {
  margin: 0;
  padding: 0;
  font-family: 'Cabin', sans-serif;
  color: #FFFFFF; }

button {
  background: none;
  border: none;
  color: #FFFFFF; }

body {
  background: #000000;
  position: relative; }
  body .App {
    position: relative; }
  body .gradient-background {
    background: radial-gradient(#373C40, #000000);
    opacity: .7;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 5; }
  body .main-content {
    position: absolute;
    display: flex;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: transparent;
    z-index: 10; }
    body .main-content .text-content {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto; }
      body .main-content .text-content .name {
        position: relative; }
        body .main-content .text-content .name span {
          position: absolute;
          left: 0;
          top: -14px;
          color: #009DFF;
          font-weight: 900; }
      body .main-content .text-content .social-media {
        display: flex;
        justify-content: center; }
        body .main-content .text-content .social-media a {
          margin: 10px 20px;
          color: white;
          outline: none;
          cursor: pointer;
          -webkit-transition: all ease 250ms;
          transition: all ease 250ms; }
          body .main-content .text-content .social-media a:hover {
            -webkit-transform: translate3d(0, 0, 123px);
                    transform: translate3d(0, 0, 123px); }
  body #top-left-triangle {
    position: absolute;
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 35vh solid red;
    border-right: 40vw solid transparent;
    z-index: 20; }

